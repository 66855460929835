import classNames from "classnames";
import { Action, Actions, GLEditableTable, GLIcon } from "@app/components";
import { ColumnLink, GridInputNumber, WijmoGrid } from "@app/components/grid";
import { PathConfig } from "@app/config/pathconfig";
import { SchoolLocale } from "@app/locales/localeid";
import {
    MaterialRequestItemDisplayPropsModel,
    RequestProductItemModel,
    SubscriptionItemModel
} from "@app/service/material";
import {
    CommonHelper,
    fmtMsg,
    GSSchoolAction,
    PurchaseTarget,
    WijmoEditHelper
} from "@app/util";
import { Modal } from "antd-min";
import { WrappedFormUtils } from "antd/lib/form/Form";
import {
    GLGlobal,
    GLLocale,
    GLUtil,
    maskThrottle,
    unmaskThrottle,
    withRouter
} from "gl-commonui";
import * as React from "react";
import { Component } from "react";
import { RouteComponentProps } from "react-router-dom";
import { InputNumber } from "wijmo/wijmo.input";
import { ProductDataParams, ProductModal } from "./product";
import { getUnitTextForLocalProduct } from "@app/page/product/components/unitprice";
import { Aggregate } from "wijmo/wijmo";
import { GroupRow, HeadersVisibility, FlexGrid } from "wijmo/wijmo.grid";
import TextArea from "antd/lib/input/TextArea";
import { isFunction } from "lodash";

const { Grid, Column } = WijmoGrid;

export interface RequestProps {
    canEdit?: boolean;
    canEditQuantity?: boolean;
    form?: WrappedFormUtils;
    locationParams: any;
    purchaseTarget: PurchaseTarget;
    productDataParams?: ProductDataParams;
    requestTitleId?: string;
    editRequestIconTitleId?: string;
    deleteRequestIconTitleId?: string;
    startUnit?: number;
    maxUnit?: number;
    requests?: RequestProductItemModel[] | SubscriptionItemModel[];
    requestId?: string;
    defaultRequest?: RequestProductItemModel | SubscriptionItemModel;
    error?: any;
    needClassColumn?: boolean;
    needPriceColumn?: boolean;
    needShippedQuantityColumn?: boolean;
    clearShippedValue?: boolean;
    dataSource?: any;
    isGridEditing?: boolean;
    shippedQuantityIsRequired?: boolean;
    needTotalPriceColumn?: boolean;
    needTotalRow?: boolean;
    stickyHeaders?: boolean;
    //onShippedQuantityChanged?: (grid, dataSource, eventData) => { valid: boolean; message: string };
    createRequestItem?: (item) => void;
    getRequestItem?: (params) => Promise<RequestProductItemModel>;
    updateRequestItem?: (item) => void;
    deleteRequestItem?: (item) => void;
    hideRequestItemEditAction: (requestItem) => boolean;
    setGridRef?: (grid: FlexGrid) => void;
    showClassAsLink: boolean;
}

export interface PurchaseRequestProps
    extends RequestProps,
        RouteComponentProps {
    showNoteColumn?: boolean;
}

export interface SubscriptionRequestProps
    extends RequestProps,
        RouteComponentProps {
    showNoteColumn?: boolean;
}

interface RequestStates {
    modalVisible?: boolean;
    modalTitle?: string;
    requestItem?: RequestProductItemModel & SubscriptionItemModel;
    isCreate: boolean;
    isSaving?: boolean;
    isOnlyNoteEdit?: boolean;
    requestItemShippingQuantity?: number | null;
}

class RequestTable extends GLEditableTable<any> {}
@withRouter
export class RequestComponent extends Component<
    PurchaseRequestProps | SubscriptionRequestProps,
    RequestStates
> {
    static defaultProps = { showClassAsLink: true };
    constructor(props) {
        super(props);
        this.state = {
            modalVisible: false,
            modalTitle: SchoolLocale.MaterialRequestItemModalTitleNew,
            requestItem: {},
            isCreate: true,
            isSaving: false,
            isOnlyNoteEdit: false,
            requestItemShippingQuantity: null
        };
        this.showRequestModal = this.showRequestModal.bind(this);
        this.handleSaveRequestItem = this.handleSaveRequestItem.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.setRequestItem = this.setRequestItem.bind(this);
    }
    // getRequestFooter() {
    //     return (
    //         <h4 className='purchase-material-total'>
    //             <FormattedMessage id={SchoolLocale.MaterialPurchaseItemFieldTotalWithColon} />
    //             {` ${MaterialHelper.GetPurchaseProductsTotal(this.props.requests)}`}
    //         </h4>
    //     );
    // }
    componentWillReceiveProps(nextProps) {
        if (this.props.requests != nextProps.requests) {
            this.setState({
                modalVisible: false,
                isSaving: false,
                isOnlyNoteEdit: false,
                requestItemShippingQuantity: null
            });
        }
        if (this.props.error != nextProps.error) {
            this.setState({ isSaving: false });
        }
    }
    getRequestModalTitle(isNew: boolean) {
        return this.props.requestTitleId;
    }
    async showRequestModal(item?: RequestProductItemModel) {
        const {
            purchaseTarget,
            requestId,
            defaultRequest,
            getRequestItem
        } = this.props;
        maskThrottle();
        const params =
            purchaseTarget == PurchaseTarget.School ||
            purchaseTarget == PurchaseTarget.ClassSchool
                ? { id: item && item.id }
                : { requestId: requestId, itemId: item && item.id };
        const requestItem = item ? await getRequestItem({ ...params }) : {};
        this.setState({
            modalVisible: true,
            modalTitle: this.getRequestModalTitle(requestItem.id == undefined),
            isCreate: item == null,
            requestItem:
                item == null
                    ? defaultRequest
                        ? defaultRequest
                        : {}
                    : requestItem
        });
        unmaskThrottle();
    }
    handleDeleteRequestItem(requestItem: RequestProductItemModel) {
        Modal.confirm({
            title: fmtMsg({
                id: SchoolLocale.MaterialRequestItemModalDeletePrompt
            }),
            okText: fmtMsg({ id: GLLocale.Ok }),
            cancelText: fmtMsg({ id: GLLocale.Cancel }),
            onOk: () => {
                this.props.deleteRequestItem({
                    ...requestItem,
                    requestId: this.props.requestId,
                    ...this.props.locationParams
                });
            }
        });
    }
    handleSaveRequestItem(values) {
        let shippedQuantityVal = null;
        let isShippingQunatityUpdateReqd = false;
        if (
            this.state.isOnlyNoteEdit &&
            values.isOnlyNoteEdit &&
            this.state.requestItemShippingQuantity
        ) {
            shippedQuantityVal = this.state.requestItemShippingQuantity;
            isShippingQunatityUpdateReqd = true;
        }

        const item =
            this.props.purchaseTarget == PurchaseTarget.School
                ? {
                      ...values,
                      id: this.state.requestItem.id,
                      shippedQuantity: shippedQuantityVal,
                      updateShippingQuantity: isShippingQunatityUpdateReqd
                  }
                : {
                      ...values,
                      requestId: this.props.requestId,
                      id: this.state.requestItem.id,
                      shippedQuantity: shippedQuantityVal,
                      updateShippingQuantity: isShippingQunatityUpdateReqd
                  };
        this.setState({ isSaving: true });
        this.state.isCreate
            ? this.props.createRequestItem({
                  ...values,
                  ...this.props.locationParams
              })
            : this.props.updateRequestItem({
                  ...item,
                  ...this.props.locationParams
              });
    }
    handleCancel() {
        this.setState({ modalVisible: false });
    }
    setRequestItem(item) {
        this.setState({ requestItem: item });
    }
    gridInitialized = flex => {
        if (isFunction(this.props.setGridRef)) {
            this.props.setGridRef(flex);
        }
        if (this.props && this.props.needTotalRow) {
            flex.columnFooters.rows.push(new GroupRow());
            flex.bottomLeftCells.setCellData(0, 0, "Total");
        }
        flex.cellEditEnded.addHandler(this.onCellEdit);
    };

    onCellEdit = (grid, event) => {
        const row = grid.rows[event.row]; // row that was edited
        const col = grid.columns[event.col]; // column that was edited

        // If the shipped quantity cell is edited then change the total price cell value.
        if (
            col.binding === MaterialRequestItemDisplayPropsModel.shippedQuantity
        ) {
            const price = Number(row.dataItem.price.replace(/[^0-9]/g, ""));
            const totalPrice =
                typeof price === "number"
                    ? Number(row.dataItem.shippedQuantity) * price
                    : null;
            // Setting the value of total price cell.
            grid.setCellData(event.row, event.col + 4, totalPrice);
            grid.collectionView.refresh();
        }
    };

    async showNoteEditModal(item?: RequestProductItemModel) {
        const {
            purchaseTarget,
            requestId,
            defaultRequest,
            getRequestItem
        } = this.props;
        maskThrottle();
        const params =
            purchaseTarget == PurchaseTarget.School ||
            purchaseTarget == PurchaseTarget.ClassSchool
                ? { id: item && item.id }
                : { requestId: requestId, itemId: item && item.id };
        const requestItem = item ? await getRequestItem({ ...params }) : {};
        this.setState({
            modalVisible: true,
            modalTitle: SchoolLocale.MaterialRequestItemModalTitleEditNote,
            isCreate: item == null,
            requestItem:
                item == null
                    ? defaultRequest
                        ? defaultRequest
                        : {}
                    : requestItem,
            isOnlyNoteEdit: true,
            requestItemShippingQuantity:
                item && item.shippedQuantity ? item.shippedQuantity : null
        });
        unmaskThrottle();
    }

    render() {
        const {
            requests,
            canEdit,
            purchaseTarget,
            requestTitleId,
            startUnit,
            maxUnit,
            productDataParams,
            needClassColumn,
            needPriceColumn,
            needShippedQuantityColumn,
            history,
            dataSource,
            isGridEditing,
            shippedQuantityIsRequired,
            hideRequestItemEditAction,
            needTotalPriceColumn,
            needTotalRow,
            stickyHeaders,
            showNoteColumn,
            showClassAsLink
        } = this.props;

        //const needFooter = (this.props.purchaseTarget == PurchaseTarget.School && this.props.requests && this.props.requests.length > 0);
        //if (needFooter) { tableProps.footer = () => this.getRequestFooter() }
        const handlers = {
            onSubmit: this.handleSaveRequestItem,
            onCancel: this.handleCancel
        };
        const canAddProduct =
            purchaseTarget == PurchaseTarget.School ||
            ((purchaseTarget == PurchaseTarget.Class ||
                purchaseTarget == PurchaseTarget.ClassSchool) &&
                GLGlobal.isActionValid(GSSchoolAction.AddProduct));
        const requestListClassName = {
            "material-request-grid": true,
            "edit-mode": isGridEditing
        };
        const shippedQuantityClassName = {
            "shipped-quantity-column": true,
            "edit-mode": isGridEditing
        };
        return (
            <div className="material-request">
                <div className="list-header">
                    <h3>{requestTitleId && fmtMsg({ id: requestTitleId })}</h3>
                    {canEdit && canAddProduct && (
                        <Actions>
                            <Action
                                materialIcon="add"
                                textLocaleId={
                                    purchaseTarget == PurchaseTarget.School ||
                                    purchaseTarget == PurchaseTarget.ClassSchool
                                        ? SchoolLocale.MaterialRequestItemNew
                                        : SchoolLocale.MaterialRequestItemNewClass
                                }
                                onClick={e => {
                                    e.preventDefault();
                                    this.showRequestModal();
                                }}
                            />
                        </Actions>
                    )}
                    <ProductModal
                        {...this.state}
                        startUnit={startUnit}
                        maxUnit={maxUnit}
                        purchaseTarget={purchaseTarget}
                        dataParams={productDataParams}
                        {...handlers}
                    />
                </div>
                <Grid
                    stickyHeaders={
                        typeof stickyHeaders === "boolean"
                            ? stickyHeaders
                            : true
                    }
                    itemsSource={requests}
                    isReadOnly={!isGridEditing}
                    className={classNames(requestListClassName)}
                    headersVisibility={
                        needTotalRow
                            ? HeadersVisibility.All
                            : HeadersVisibility.Column
                    }
                    initialized={this.gridInitialized}
                    autoRowHeights={true}
                    // cellEditEnded={(grid, event) => this.onCellEdit(grid, dataSource, event)}
                >
                    {needClassColumn && (
                        <Column
                            binding={
                                MaterialRequestItemDisplayPropsModel.className
                            }
                            header={fmtMsg({
                                id: SchoolLocale.MaterialPurchaseItemFieldClass
                            })}
                            render={(value, item) => {
                                const {
                                    regionId,
                                    schoolId,
                                    campusId
                                } = this.props.locationParams;
                                return !showClassAsLink ||
                                    canEdit ||
                                    !item.classId ? (
                                    value
                                ) : (
                                    <ColumnLink
                                        history={history}
                                        url={GLUtil.pathStringify(
                                            PathConfig.Students,
                                            {
                                                regionId,
                                                schoolId,
                                                campusId,
                                                classId: item.classId
                                            }
                                        )}
                                    >
                                        {value}
                                    </ColumnLink>
                                );
                            }}
                        />
                    )}
                    <Column
                        binding={
                            MaterialRequestItemDisplayPropsModel.productName
                        }
                        header={fmtMsg({
                            id: SchoolLocale.MaterialPurchaseItemFieldProduct
                        })}
                    />
                    <Column
                        align="left"
                        binding={MaterialRequestItemDisplayPropsModel.unitText}
                        header={fmtMsg({
                            id: SchoolLocale.MaterialPurchaseItemFieldUnit
                        })}
                    />
                    <Column
                        binding={MaterialRequestItemDisplayPropsModel.quantity}
                        header={fmtMsg({
                            id: SchoolLocale.MaterialPurchaseItemFieldQuantity
                        })}
                        aggregate={Aggregate.Sum}
                    />
                    {needShippedQuantityColumn && (
                        <Column
                            binding={
                                MaterialRequestItemDisplayPropsModel.shippedQuantity
                            }
                            header={fmtMsg({
                                id:
                                    SchoolLocale.MaterialOrderPageCommonFieldShippedQuantity
                            })}
                            cssClass={classNames(shippedQuantityClassName)}
                            isReadOnly={false}
                            renderEditor={(context) => <GridInputNumber isRequired={false} context={context} min={0} max={9999} format="n0" />}
                            align="right"
                            aggregate={Aggregate.Sum}
                        />
                    )}
                    <Column
                        binding={
                            MaterialRequestItemDisplayPropsModel.requestedBy2Name
                        }
                        header={fmtMsg({
                            id:
                                SchoolLocale.MaterialSubscriptionItemFieldRequestBy
                        })}
                    />
                    <Column
                        binding={
                            MaterialRequestItemDisplayPropsModel.requestDateFormatted
                        }
                        header={fmtMsg({
                            id:
                                SchoolLocale.MaterialSubscriptionItemFieldRequestDate
                        })}
                    />
                    {needPriceColumn && (
                        <Column
                            cssClass="purchase-price"
                            binding={MaterialRequestItemDisplayPropsModel.price}
                            header={fmtMsg({
                                id: SchoolLocale.MaterialPurchaseItemFieldPrice
                            })}
                            render={value => CommonHelper.formatNumber(value)}
                            align="right"
                        />
                    )}
                    {needTotalPriceColumn && (
                        <Column
                            binding={
                                MaterialRequestItemDisplayPropsModel.totalPrice
                            }
                            header={fmtMsg({
                                id:
                                    SchoolLocale.MaterialPurchaseItemFieldTotalPrice
                            })}
                            aggregate={Aggregate.Sum}
                            align="right"
                        />
                    )}
                    {showNoteColumn && (
                        <Column
                            binding={MaterialRequestItemDisplayPropsModel.note}
                            header={fmtMsg({
                                id:
                                    SchoolLocale.MaterialSubscriptionItemFieldNote
                            })}
                            minWidth={300}
                            maxWidth={300}
                            wordWrap={true}
                        />
                    )}
                    {isGridEditing && (
                        <Column
                            allowSorting={false}
                            binding={MaterialRequestItemDisplayPropsModel.note}
                            render={(value, item) => {
                                return (
                                    <div className="table-item-actions">
                                        <GLIcon
                                            type="icon-edit"
                                            key={0}
                                            labelId={
                                                this.props
                                                    .editRequestIconTitleId
                                            }
                                            onClick={() =>
                                                this.showNoteEditModal(item)
                                            }
                                        />
                                    </div>
                                );
                            }}
                            maxWidth={50}
                        />
                    )}
                    {canEdit && (
                        <Column
                            allowSorting={false}
                            render={(value, item) => {
                                return hideRequestItemEditAction &&
                                    hideRequestItemEditAction(item) ? null : (
                                    <div className="table-item-actions">
                                        <GLIcon
                                            type="icon-edit"
                                            key={0}
                                            labelId={
                                                this.props
                                                    .editRequestIconTitleId
                                            }
                                            onClick={() =>
                                                this.showRequestModal(item)
                                            }
                                        />
                                        <GLIcon
                                            type="icon-delete"
                                            key={1}
                                            labelId={
                                                this.props
                                                    .deleteRequestIconTitleId
                                            }
                                            onClick={() =>
                                                this.handleDeleteRequestItem(
                                                    item
                                                )
                                            }
                                        />
                                    </div>
                                );
                            }}
                        />
                    )}
                </Grid>
            </div>
        );
    }
}
